import { Component } from '@angular/core';

import { MenuController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root', 
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  appPagesToUse = [];
  private appPagesLoggedIn = [{
    title: 'Plants',
    url: '/plants',
    icon: 'leaf-outline'
  },{
    title: 'Management',
    url: '/management',
    icon: 'leaf-outline'
  }];
  currentSession:any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar, private navCtrl : NavController, private router:Router
  ) {
    this.initializeApp();
    this.appPagesToUse =  this.appPagesLoggedIn;
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();      
    let user = localStorage.getItem("user");
    this.currentSession = JSON.parse(user);
    console.log("currentSession",this.currentSession);
    if(this.currentSession && this.currentSession !== []){
      this.router.navigate(['plants']) ;
    }else{
      this.router.navigate(['/']) ;
    }
    });
  }
  onNavItemClick(navItem: { title: string, url: string, icon: string }){
    let currPathName = window.location.pathname;
    if (currPathName || currPathName.includes(navItem.url)) {
      this.navCtrl.navigateRoot([navItem.url]);
    }
  }
  logout() {
    localStorage.clear();
    this.navCtrl.navigateRoot(["/"]);
  }
}
